import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/kylesoeltz/git/reconnect-v2/src/components/layouts/post-layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        authors {
          id
          name
        }
        categories {
          id
          name
        }
      }
    }
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 6
      filter: {frontmatter: {category: {eq: "hiking-backpacking-gear-reviews" }}}
    ) {
      edges {
        node {
          frontmatter {
            author
            date
            path
            title
            featuredImage
          }
          slug
          timeToRead
          excerpt(truncate: false, pruneLength: 200)
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Figcaption = makeShortcode("Figcaption");
const ProsAndCons = makeShortcode("ProsAndCons");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Outdoor-Research-Baker-Modular-Mitts/dp/B0059BISMC/ref=as_li_ss_tl?s=sporting-goods&ie=UTF8&qid=1521142758&sr=1-1&keywords=mt+baker+mittens&linkCode=ll1&tag=reconnectlife-20&linkId=5e8b48cba78abe71a4c0d54eda57b3fc"
      }}>{`Outdoor Research Mt. Baker Modular Mittens`}</a>{` are a two part mitten designed to keep your fingers warm in extremely cold conditions. They have a waterproof shell with a heavy duty fleece linear on the inside.`}</p>
    <p><img parentName="p" {...{
        "src": "mt-baker-mits-shell-500x375.jpg",
        "alt": "Mount Baker Mitts Shell"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Mt. Baker Mittens Waterproof Shell</Figcaption>
    <h2>{`Performance`}</h2>
    <p>{`If you need your hands to be warm, then these mittens will do the trick. I picked them up after I spent several hours with freezing hands on a winter hike. I needed a solution and boy did these guys come through.`}</p>
    <p>{`Using these, plus a lightweight glove liner, I have had warm and toasty hands down to zero degrees. I haven’t had any opportunities to go colder with them yet, but I feel confident I could go another 5-10 degrees with this setup before needing something warmer.`}</p>
    <p>{`When Outdoor Research describes `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Outdoor-Research-Baker-Modular-Mitts/dp/B0059BISMC/ref=as_li_ss_tl?s=sporting-goods&ie=UTF8&qid=1521142758&sr=1-1&keywords=mt+baker+mittens&linkCode=ll1&tag=reconnectlife-20&linkId=5e8b48cba78abe71a4c0d54eda57b3fc"
      }}>{`Mt. Baker Modular Mittens`}</a>{` as being waterproof, they mean it. I have been in freezing rain and climbing through deep snow drifts, and the shell has kept my hands nice and dry in both occasions. Of course like most waterproof gear this works both ways- if you put wet hands in the shell, it’s not going to be drying out anytime soon.`}</p>
    <p><img parentName="p" {...{
        "src": "hiker-in-snow-storm-500x375.jpg",
        "alt": "Hiker in snow storm"
      }}></img></p>
    <Figcaption mdxType="Figcaption">It was cold out, but my hands were toasty</Figcaption>
    <h2>{`A Layer System For Your Hands`}</h2>
    <p>{`The two part design allows you to have the same layer control that you have over your body but with your hands. I typically bring a pair of glove liners as well, allowing me to alternate between varying degrees of warmth, dexterity, and waterproof-ness. `}</p>
    <p>{`As I said above, I feel comfortable going down to 5-10 below with this setup, but with the layer system I can also go up to the 30s. If it’s cold but dry I might go just the fleece layer. If it’s wet and a little chilly, then just the shell. Cold but not thatttt cold? Shell plus my liners. `}</p>
    <p><img parentName="p" {...{
        "src": "mt-baker-mits-fleece-closed-500x375.jpg",
        "alt": "Mount Baker Mitts Fleece Liner, Closed"
      }}></img></p>
    <Figcaption mdxType="Figcaption">The Fleece Liner</Figcaption>
    <h2>{`Good Dexterity ‘Options’`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Outdoor-Research-Baker-Modular-Mitts/dp/B0059BISMC/ref=as_li_ss_tl?s=sporting-goods&ie=UTF8&qid=1521142758&sr=1-1&keywords=mt+baker+mittens&linkCode=ll1&tag=reconnectlife-20&linkId=5e8b48cba78abe71a4c0d54eda57b3fc"
      }}>{`Mt. Baker Modular Mittens`}</a>{` inner fleece has convertible capabilities allowing you to expose your fingers and thumb. There are magnets on the fleece that keeps the fleece folded open without it continuously bouncing back while you are trying to tinkle with gear/food/bear attacks.`}</p>
    <p>{`If you combine this with the wrist straps of the shell, you can quickly toss off the outer layer, fold back the fleece, and you now have full dexterity without having had to remove the mittens and put them in the snow or back in your pack.`}</p>
    <p>{`If you are a bushwhacker, there is one unexpected downside to the magnets on the fleece. While compass navigating, the magnet can cause the compass to point in the wrong direction. So if you intend on doing a lot of compass navigation, you may want to look elsewhere.`}</p>
    <p><img parentName="p" {...{
        "src": "mt-baker-mits-fleece-open-500x375.jpg",
        "alt": "Mount Baker Mitts Fleece Liner, Open"
      }}></img></p>
    <Figcaption mdxType="Figcaption">The Fleece Liner Opened Up</Figcaption>
    <h2>{`Durability`}</h2>
    <p>{`I have had the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Outdoor-Research-Baker-Modular-Mitts/dp/B0059BISMC/ref=as_li_ss_tl?s=sporting-goods&ie=UTF8&qid=1521142758&sr=1-1&keywords=mt+baker+mittens&linkCode=ll1&tag=reconnectlife-20&linkId=5e8b48cba78abe71a4c0d54eda57b3fc"
      }}>{`Mt. Baker Modular Mittens`}</a>{` for 4 seasons now and they show no signs of breaking down or failing. The material seems strong and durable. I have taken them on thick bushwhacks before and they have held up perfectly while pushing through trees and scraping on branches.`}</p>
    <h2>{`Cost`}</h2>
    <p>{`For the warmth and waterproof capabilities that these mittens provide, they are very reasonably priced. Some of the features found in more expensive mittens include increased insulation, waterproofing of the inner fleece (for when it’s worn separately), and even ones with battery powered heating. But for warmth to cost ratio, I would certainly recommend the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Outdoor-Research-Baker-Modular-Mitts/dp/B0059BISMC/ref=as_li_ss_tl?s=sporting-goods&ie=UTF8&qid=1521142758&sr=1-1&keywords=mt+baker+mittens&linkCode=ll1&tag=reconnectlife-20&linkId=5e8b48cba78abe71a4c0d54eda57b3fc"
      }}>{`Outdoor Research Mt. Baker Modular Mittens`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Outdoor-Research-Baker-Modular-Mitts/dp/B0059BISMC/ref=as_li_ss_tl?s=sporting-goods&ie=UTF8&qid=1521142758&sr=1-1&keywords=mt+baker+mittens&linkCode=ll1&tag=reconnectlife-20&linkId=5e8b48cba78abe71a4c0d54eda57b3fc"
      }}><img parentName="a" {...{
          "src": "mount-baker-meteor-mitts-product-shot-500x281.jpg",
          "alt": "Mount Baker Mittens"
        }}></img></a></p>
    <Figcaption mdxType="Figcaption">[Mt. Baker Modular Mittens](https://www.amazon.com/Outdoor-Research-Baker-Modular-Mitts/dp/B0059BISMC/ref=as_li_ss_tl?s=sporting-goods&ie=UTF8&qid=1521142758&sr=1-1&keywords=mt+baker+mittens&linkCode=ll1&tag=reconnectlife-20&linkId=5e8b48cba78abe71a4c0d54eda57b3fc)</Figcaption>
    <h2>{`Summary`}</h2>
    <ProsAndCons pros={['Waterproof', 'Warm', 'Multi-layer system for temperature flexibility', 'Affordable', 'Design allows for increased dexterity when needed']} cons={['Magnet can mess with a compass']} mdxType="ProsAndCons" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      